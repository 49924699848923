import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";

import {
  TextField,
  SelectField,
  MandatoryNote,
  LoadingButton,
  CommonTextField,
} from "../../../../components";
import EventCard from "../EventCard";
import { useAxios } from "../../../../hooks";

import { TiTicket } from "react-icons/ti";
import { AiOutlineDelete } from "react-icons/ai";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CustomField from "./CustomField";
import EventBadge from "./EventBadge";
import SummaryTable from "./SummaryTable";

function Form({
  formik,
  eventID,
  eventObject,
  stateOptions,
  badgeOptions,
  parking,
  setParking,
  isDependent,
  dependentList,
  vehicleDetails,
  setIsDependent,
  customFields,
}) {
  const navigate = useNavigate();
  const axiosApi = useAxios({ baseURL: "mox_api_2" });

  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const [memberCount, setMemberCount] = useState(formik.values.memberCount);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [vehicleCount, setVehicleCount] = useState(1);

  const handleInputChange = (e) => {
    const regex = /^[A-Za-z\s]*$/;

    const inputValue = e.target.value;

    if (regex.test(inputValue)) {
      formik.handleChange(e);
    }
  };

  const handleDownloadPDF = (link) => {
    const pdfLink = link;

    window.open(pdfLink, "_blank");
  };

  const handleEventBadgeSelect = (ID, index) => {
    console.log(ID, index, "ID, index");
    const selectedEventId = ID;

    const selectedEvent = badgeOptions.find(
      (event) => event?._id === selectedEventId
    );

    formik.setFieldValue(
      `visitorData[${index}].badge_id`,
      formik.values.visitorData[index]?.badge_id ? "" : selectedEvent?._id
    );

    formik.setFieldValue(
      `visitorData[${index}].badge_id_amount`,
      formik.values.visitorData[index]?.badge_id_amount
        ? 0
        : parseInt(selectedEvent?.price)
    );

    if (eventID && !formik.values.eventAmount) {
      formik.setFieldValue("eventAmount", parseInt(eventObject.ticket_cost));
    }
  };

  const handleStateChange = (event, index) => {
    setDistrictOptions([]);

    const selectedState = event.target.value;

    formik.setFieldValue(`visitorData[${index}].state`, selectedState);

    if (eventID && !formik.values.eventAmount) {
      formik.setFieldValue("eventAmount", eventObject.ticket_cost);
    }

    formik.setFieldValue(`visitorData[${index}].district`, "");

    fetchDistricts(selectedState);
  };

  const fetchDistricts = async (selectedState) => {
    try {
      const response = await axiosApi({
        url: `event/booking/districts?state_id=${selectedState}`,
        method: "GET",
      });
      if (response.status) {
        const formattedDistricts = response.data.map((district) => ({
          id: district?.district_id,
          name: district?.district_name,
        }));

        setDistrictOptions(formattedDistricts);
      }
    } catch (error) {
      console.error("Error in fetching district list", error);
    }
  };

  function handleAddMember() {
    setMemberCount(memberCount + 1);
    formik.setFieldValue("memberCount", memberCount + 1);
  }

  function handleRemoveMember(index) {
    let newVisitorData = formik.values.visitorData.filter(
      (_, ind) => ind !== index
    );

    setMemberCount(memberCount - 1);
    formik.setFieldValue("visitorData", newVisitorData);
    formik.setFieldValue("memberCount", memberCount - 1);
  }

  useEffect(() => {
    if (formik.values.state0) {
      fetchDistricts(formik.values.state0);
    }
  }, []);

  function handleFillDependent(name, ID, index) {
    let dependent = dependentList.filter((item) => item._id === ID);

    let value = {
      name: dependent[0]?.first_name + " " + dependent[0]?.last_name,
      phone_number: dependent[0]?.phone_number,
      email_id: dependent[0]?.email_id,
      gender: dependent[0]?.gender,
      [name]: ID,
      is_dependent: true,
    };

    formik.setFieldValue(`visitorData[${index}]`, value);
  }

  return (
    <Fragment>
      <Grid
        container
        spacing={3}
        sx={{
          textTransform: "capitalize",
        }}
      >
        {eventObject.event_name && (
          <Grid item xs={12} sm={12}>
            <EventCard
              eventObject={eventObject}
              handleDownloadPDF={handleDownloadPDF}
            />
          </Grid>
        )}

        <Grid
          item
          container
          spacing={3}
          m={"10px 0px"}
          sx={{
            // boxShadow: "rgb(232, 196, 144) 3px 3px 5px -2px",
            padding: "10px 10px 10px 0px !important",
            borderRadius: "12.5px",
            border: "1px solid rgb(232, 222, 207)",
          }}
        >
          {console.log(formik, "formik")}
          <Grid item xs={6}>
            <TextField
              name="visitorData[0].name"
              label={"Name"}
              formikProps={formik}
              disabled={
                Boolean(state?.first_name) ||
                formik.values.paymentStatue === "Pending"
              }
            />
          </Grid>

          <SelectField
            label="Select Your Gender"
            name="visitorData[0].gender"
            value={formik.values["visitorData[0]"]?.gender}
            options={[
              { id: "Male", name: "Male" },
              { id: "Female", name: "Female" },
            ]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.district && formik.errors.district}
            required
            disabled={formik.values.paymentStatue === "Pending"}
          />

          <Grid mt={1} item xs={12}>
            <TextField
              name="visitorData[0].phone_number"
              label={"Mobile No"}
              formikProps={formik}
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Enter Your Email"
              placeholder="Enter Your Email"
              name="visitorData[0].email_id"
              required
              formikProps={formik}
              disabled={
                Boolean(state?.first_name) ||
                formik.values.paymentStatue === "Pending"
              }
            />
          </Grid>

          <SelectField
            label="Choose Your State"
            value={formik.values[`visitorData[0].state`]}
            onChange={(e) => handleStateChange(e, 0)}
            options={stateOptions}
            error={
              formik.touched[`visitorData[0].state`] &&
              Boolean(formik.errors[`visitorData[0].state`])
            }
            required
            disabled={formik.values.paymentStatue === "Pending"}
          />

          <SelectField
            label="Choose Your District"
            name="visitorData[0].district"
            value={formik.values[`visitorData[0].district`]}
            options={districtOptions}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched[`visitorData[0].district`] &&
              formik.errors[`visitorData[0].district`]
            }
            required
            disabled={formik.values.paymentStatue === "Pending"}
          />

          {badgeOptions.length > 0 && (
            <EventBadge
              index={0}
              eventID={eventID}
              eventObject={eventObject}
              formikProps={formik}
              badgeOptions={badgeOptions}
            />
          )}

          <Grid item xs={6}>
            <CommonTextField
              label="Enter Your organization's Name(Optional)"
              placeholder="Enter Your organization's Name(Optional)"
              name="organization"
              type="text"
              value={formik.values.organization}
              onChange={handleInputChange}
              onBlur={formik.handleBlur}
              disabled={formik.values.paymentStatue === "Pending"}
            />
          </Grid>

          <Grid item xs={6}>
            <CommonTextField
              label="Enter Your Designation(Optional)"
              placeholder="Enter Your Designation Name(Optional)"
              name="designation"
              type="text"
              value={formik.values.designation}
              onChange={handleInputChange}
              onBlur={formik.handleBlur}
              disabled={formik.values.paymentStatue === "Pending"}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomField
              formikProps={formik}
              customFields={customFields}
              visitorDataIndex={0}
            />
          </Grid>
        </Grid>

        {Array(memberCount)
          .fill(true)
          .map((item, index) => {
            return (
              <Grid
                item
                container
                spacing={3}
                m={"20px 0px"}
                sx={{
                  // boxShadow: "rgb(232, 196, 144) 3px 3px 5px -2px",
                  padding: "10px 10px 10px 0px !important",
                  borderRadius: "12.5px",
                  border: "1px solid rgb(232, 222, 207)",
                  position: "relative",
                }}
              >
                <Grid
                  item
                  container
                  sx={{
                    justifyContent: "space-between",
                    position: "absolute",
                    top: "-30px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      alignSelf: "center",
                      color: "rgb(215 156 49)",
                      background: "white",
                      padding: "10px",
                      fontWeight: 700,
                    }}
                  >
                    {index + 1} Person
                  </Typography>
                  {!Boolean(formik.values.paymentStatue === "Pending") && (
                    <AiOutlineDelete
                      style={{
                        color: "white",
                        padding: "3px",
                        fontSize: "35px",
                        backgroundColor: "#ff9191",
                        backgroundImage:
                          "linear-gradient(164deg, #ff9191 0%, #FF2525 74%)",
                        borderRadius: "5px",

                        boxShadow: "1px 2px 4px 1px #bbbaba",
                      }}
                      onClick={() => handleRemoveMember(index + 1)}
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    select
                    name={`visitorData[${index + 1}]dependentMemberID`}
                    label="Select Member"
                    placeholder="Select Member"
                    formikProps={formik}
                    onChange={(e) =>
                      handleFillDependent(
                        "dependentMemberID",
                        e.target.value,
                        index + 1
                      )
                    }
                  >
                    {dependentList.map((item, index) => {
                      return (
                        <MenuItem value={item._id}>
                          {item.first_name + item.last_name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    name={`visitorData[${index + 1}].name`}
                    label={"Name"}
                    formikProps={formik}
                    disabled={
                      formik.values.paymentStatue === "Pending" ||
                      formik.values.visitorData[`${index + 1}`]
                        ?.dependentMemberID
                    }
                  />
                </Grid>

                {formik.values.paymentStatue === "Pending" ||
                formik.values.visitorData[`${index + 1}`]?.dependentMemberID ? (
                  <Grid item xs={6}>
                    <TextField
                      name={`visitorData[${index + 1}].gender`}
                      label="Select Your Gender"
                      formikProps={formik}
                      disabled={
                        formik.values.paymentStatue === "Pending" ||
                        formik.values.visitorData[`${index + 1}`]
                          ?.dependentMemberID
                      }
                    />
                  </Grid>
                ) : (
                  <SelectField
                    label="Select Your Gender"
                    name={`visitorData[${index + 1}].gender`}
                    value={formik.values.visitorData[`${index + 1}`]?.gender}
                    options={[
                      { id: "Male", name: "Male" },
                      { id: "Female", name: "Female" },
                    ]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.district && formik.errors.district}
                    required
                    xs={6}
                  />
                )}

                <CommonTextField
                  label="Enter Your Email"
                  placeholder="Enter Your Email"
                  name={`visitorData[${index + 1}].email_id`}
                  type="email"
                  value={formik.values.visitorData[`${index + 1}`]?.email_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched[`visitorData[${index + 1}].email_id`] &&
                    Boolean(formik.errors[`visitorData[${index + 1}].email_id`])
                  }
                  required
                  disabled={
                    formik.values.paymentStatue === "Pending" ||
                    formik.values.visitorData[`${index + 1}`]?.dependentMemberID
                  }
                />

                <Grid mt={1} item xs={12}>
                  <TextField
                    name={`visitorData[${index + 1}].phone_number`}
                    label={"Mobile No"}
                    formikProps={formik}
                    required
                    disabled={
                      formik.values.paymentStatue === "Pending" ||
                      formik.values.visitorData[`${index + 1}`]
                        ?.dependentMemberID
                    }
                  />
                </Grid>

                {badgeOptions.length > 0 && (
                  <EventBadge
                    eventID={eventID}
                    index={index + 1}
                    formikProps={formik}
                    eventObject={eventObject}
                    badgeOptions={badgeOptions}
                  />
                )}

                <Grid item xs={12}>
                  <CustomField
                    formikProps={formik}
                    customFields={customFields}
                    visitorDataIndex={index + 1}
                  />
                </Grid>
              </Grid>
            );
          })}

        {eventObject.is_parking && (
          <Typography variant="body1" sx={{ fontWeight: "700" }}>
            <Checkbox
              checked={parking}
              style={{ alignSelf: "baseline" }}
              onClick={() => {
                setParking(!parking);
                formik.setFieldValue("vehicle", []);
              }}
            />
            Select this option to reserve a parking slot for the event by
            providing your vehicle details
          </Typography>
        )}

        <Grid item container>
          {parking && (
            <Fragment>
              {Array(vehicleCount)
                .fill(true)
                .map((item, index) => {
                  return (
                    <Grid
                      item
                      container
                      spacing={3}
                      m={"2px 0px"}
                      sx={{
                        // boxShadow: "rgb(232, 196, 144) 3px 3px 5px -2px",
                        padding: "10px 10px 10px 0px !important",
                        borderRadius: "12.5px",

                        border: "1px solid rgb(232, 222, 207)",
                        position: "relative",
                      }}
                    >
                      <Grid item xs={6}>
                        <TextField
                          select
                          formikProps={formik}
                          label="Choose Vehicle Type"
                          name={`vehicle[${index}].vehicle_type`}
                          value={
                            formik.values[`vehicle[${index}].vehicle_type`]
                          }
                        >
                          {vehicleDetails.map((item) => {
                            return (
                              <MenuItem
                                value={item.vehicle_type}
                                disabled={
                                  formik.values.vehicle.filter((ite) => {
                                    return (
                                      ite.vehicle_type == item.vehicle_type
                                    );
                                  }).length
                                }
                              >
                                {`${item.vehicle_type}  - INR ${item.amount}`}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>

                      <Grid item xs={4}>
                        <TextField
                          name={`vehicle[${index}].vehicle_number`}
                          label={"Vehicle No"}
                          formikProps={formik}
                        />
                      </Grid>

                      <Grid item xs={2} alignContent={"center"}>
                        {index == 0 ? (
                          <Fragment>
                            {vehicleDetails.length >
                              Array(vehicleCount).length && (
                              <AddCircleIcon
                                onClick={() => {
                                  formik.setFieldValue(
                                    "vehicleCount",
                                    vehicleCount + 1
                                  );
                                  setVehicleCount((prev) => prev + 1);
                                }}
                              />
                            )}
                          </Fragment>
                        ) : (
                          <AiOutlineDelete
                            style={{
                              color: "white",
                              padding: "3px",
                              fontSize: "27.5px",
                              backgroundColor: "#ff9191",
                              backgroundImage:
                                "linear-gradient(164deg, #ff9191 0%, #FF2525 74%)",
                              borderRadius: "5px",

                              boxShadow: "1px 2px 4px 1px #bbbaba",
                            }}
                            onClick={() => {
                              formik.setFieldValue(
                                "vehicleCount",
                                vehicleCount - 1
                              );
                              let newVehicleValue = [...formik.values.vehicle];
                              newVehicleValue.pop();
                              formik.setFieldValue("vehicle", newVehicleValue);

                              setVehicleCount((prev) => prev - 1);
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
            </Fragment>
          )}
        </Grid>

        <Grid item container>
          <LoadingButton
            loading={loading}
            fullWidth
            style={{
              fontWeight: 800,
              margin: "2px 0px",
              color: "#ffffff",
              "-webkit-text-stroke-width": "1px",
              wordSpacing: " 3px",
              letterSpacing: "2px",
            }}
            onClick={handleAddMember}
            disabled={
              memberCount > 3 || formik.values.paymentStatue === "Pending"
            }
          >
            Add More Member
          </LoadingButton>
        </Grid>

        {Boolean(
          formik.values.eventAmount ||
            Object.keys(formik.values)
              .filter((item) => item.includes("badge_id_amount"))
              .filter((item) => formik.values[item]).length
        ) && (
          <SummaryTable
            formikProps={formik}
            eventObject={eventObject}
            vehicleDetails={vehicleDetails}
            badgeOptions={badgeOptions}
          />
        )}
      </Grid>

      <MandatoryNote />

      <Grid item container spacing={{ sl: 2 }}>
        <Grid item xs={12} sl={6}>
          <LoadingButton
            loading={loading}
            onClick={formik.handleSubmit}
            // disabled={uploadLoading || !formik.dirty || !formik.isValid}
            fullWidth
            style={{ fontWeight: 800, marginTop: "10px" }}
          >
            {`${
              formik.values.eventAmount + formik.values?.badge_id_amount == 0
                ? "Take Next Step"
                : "Proceed to Pay"
            }`}

            {Boolean(
              formik.values.eventAmount + formik.values?.badge_id_amount
            ) &&
              `: INR ${
                parseInt(
                  formik.values.eventAmount * formik.values.visitorData?.length
                ) +
                  formik.values.vehicle?.reduce((sum, current, index) => {
                    return (
                      (parseInt(
                        vehicleDetails.filter((ite, index) => {
                          return ite.vehicle_type == current?.vehicle_type;
                        })[0]?.amount
                      ) || 0) + sum
                    );
                  }, 0) +
                  formik.values.visitorData?.reduce((sum, current, index) => {
                    return (current?.badge_id_amount || 0) + sum;
                  }, 0) || 0
              }`}
          </LoadingButton>
        </Grid>

        <Grid item xs={12} sl={6}>
          <LoadingButton
            loading={loading}
            onClick={() => navigate(-1)}
            fullWidth
            backgroundColor="rgba(245, 240, 229, 0)"
            style={{ fontWeight: 800, color: "black" }}
          >
            Back{" "}
          </LoadingButton>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Form;
