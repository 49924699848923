import { Formik } from "formik";
import { Fragment } from "react";
import { styled } from "@mui/system";
import { useDispatch } from "react-redux";
import { Grid, Paper } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

import FormDisplay from "./FormDisplay";
import { useAlert, useAxios } from "../../hooks";
import { signInMember } from "../../redux/slices/memberSlice";
import { signInDetailsObject, validationSchema } from "./signInDetailsObject";
import DrawerAppBar from "../../layouts/application layout components/Navbar";

const FormBox = styled(Paper)`
  width: 100%;
  padding: 20px;
  // box-shadow: 0px 0px 2.5px 1px rgba(90, 90, 90, 0.51);
  border-radius: 5px;
  background: white;
  min-height: 20vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
  border-radius: 7.5px;
  flex-direction: row;
`;

export default function Form() {
  const axios = useAxios({ baseURL: "mox_api_2", disableAuthToken: true });
  const { alert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search, state } = useLocation();

  return (
    <Fragment>
      <Grid container display={"flex"} justifyContent={"center"}>
        <Grid item xs={8}>
          <FormBox>
            <Formik
              enableReinitialize
              initialValues={signInDetailsObject}
              validationSchema={validationSchema}
              validateOnMount
              onSubmit={(formData, { setSubmitting }) => {
                console.log(formData, "formData");
                axios({
                  url: "/admin/member/login",
                  method: "POST",
                  data: {
                    username: formData.email,
                    password: formData.password,
                    // agent_name: formData.agent_name,
                  },
                  disableRedirect: true,
                })
                  .then(async (res) => {
                    if (res.status) {
                      sessionStorage.setItem("authToken", res.data.auth_token);
                      sessionStorage.setItem(
                        "displayName",
                        res.data.agent_name
                      );

                      await dispatch(
                        signInMember({
                          authToken: res.data.auth_token,
                          agentName: res.data.agent_name,
                        })
                      );

                      dispatch(alert({ message: "Welcome!", type: "success" }));

                      navigate("/main-gate-entry-back");

                      setSubmitting(false);
                    } else {
                      setSubmitting(false);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              {(formikProps) => (
                <form onSubmit={formikProps.handleSubmit}>
                  <FormDisplay formikProps={formikProps} />
                </form>
              )}
            </Formik>
          </FormBox>
        </Grid>
      </Grid>
    </Fragment>
  );
}
