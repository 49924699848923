import { Formik } from "formik";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, Grid, MenuItem, Tooltip, Typography } from "@mui/material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import SyncIcon from "@mui/icons-material/Sync";
import { BsCameraFill } from "react-icons/bs";

import ImgCapture from "./ImgCapture";
import { useAxios } from "../../hooks";
import { Label, LoadingButton, OuterBox, TextField } from "../../components";
import { setDisplayName } from "../../redux/slices/memberSlice";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";

const MainGateEntryWithBackCamera = () => {
  const axios = useAxios({
    baseURL: "mox_api_2",
  });

  const dispatch = useDispatch();

  const location = useLocation();

  const navigate = useNavigate();

  const videoRef = useRef(null);

  const { authToken, displayName, eventID } = useSelector(
    (state) => state.memberDetails
  );

  const [eventList, setEventList] = useState([]);
  const [gates, setGates] = useState([]);
  const [userStep, setUserStep] = useState("");
  const [cameraScreen, setCameraScreen] = useState("environment");
  const [loading, setLoading] = useState(false);
  const [selectedGate, setSelectedGate] = useState(
    Number(sessionStorage.getItem("gateMateNo")) || 0
  );

  const [entryStatus, setEntryStatus] = useState(false);
  const [guardDetails, setGuardDetails] = useState({
    name: displayName || "",
    gateNo: 0,
    QRCode: "",
    ImgData: "",
    eventID: "",
    entityType: "",
  });

  const [bookingExistsStatus, setBookingExistsStatus] = useState(undefined);
  const [mainEntryStatus, setMainEntryStatus] = useState(undefined);
  const [faceVerificationStatus, setFaceVerificationStatus] =
    useState(undefined);

  const [agentName, setAgentName] = useState(displayName || "");
  const [appointmentStatus, setAppointmentStatus] = useState(undefined);

  const [userDetail, setUserDetail] = useState({});

  useEffect(() => {
    if (Boolean(displayName)) {
      setUserStep("QRcode");
    }
  }, []);

  const StatusMessage = ({
    icon: Icon,
    iconColor,
    text,
    buttonText,
    onClick,
  }) => (
    <Grid
      container
      display="flex"
      sx={{ flexDirection: "column" }}
      alignItems="center"
      justifyContent="center"
    >
      <Icon sx={{ fontSize: "80px", color: iconColor, mb: "30px" }} />
      <audio
        autoPlay
        src="https://broadcast-contact-numbers.s3.ap-south-1.amazonaws.com/output_1719227737.921351_aws.wav"
      ></audio>
      <Typography
        variant="h1"
        component="h2"
        sx={{
          fontWeight: 700,
          fontSize: "18px",
          textAlign: "center",
          color: iconColor,
        }}
      >
        {text}
      </Typography>
      <Button
        variant="outlined"
        sx={{ mt: "30px", color: iconColor, border: `1px solid ${iconColor}` }}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Grid>
  );

  const resetStatuses = () => {
    setFaceVerificationStatus(undefined);
    setAppointmentStatus(undefined);
    setMainEntryStatus(undefined);
    setBookingExistsStatus(undefined);
  };

  useEffect(() => {
    return () => {
      const stopCamera = () => {
        if (videoRef.current && videoRef.current?.srcObject) {
          const tracks = videoRef.current.srcObject?.getTracks();
          tracks.forEach((track) => track.stop());
          videoRef.current.srcObject = null;
        }
      };

      stopCamera();
    };
  }, [location]);

  return (
    <OuterBox>
      {!entryStatus &&
        faceVerificationStatus === undefined &&
        appointmentStatus === undefined && (
          <Formik
            initialValues={guardDetails}
            onSubmit={(formData, { setSubmitting }) => {
              setSubmitting(true);

              let url =
                selectedGate === 1
                  ? "/booking/verify-visitor"
                  : "booking/verify/dome-visitor";

              axios({
                url: "/entry/entry",
                method: "POST",
                data: {
                  machine_id: "TW50000022400266",
                  // ...(selectedGate === 1 && { id: formData.QRCode }),
                  face_data: formData.ImgData,
                  // is_manual: false,
                  // agent_name: agentName,
                  // name: "",
                  // booking_id: "",
                  // event_id: eventID || sessionStorage.getItem("eventID"),
                },
                customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
                disableRedirect: true,
              }).then((response) => {
                if (response.status) {
                  setEntryStatus(
                    response.data?.appointment_status &&
                      response.data?.face_verification
                  );
                  setUserDetail(response.data?.appointment_data);
                } else {
                  // setBookingExistsStatus(response.data?.booking_exist);
                  setAppointmentStatus(response.data?.appointment_status);
                  setFaceVerificationStatus(response.data?.face_verification);
                  // setMainEntryStatus(response.data?.main_entry);
                }
                setSubmitting(false);
              });
            }}
          >
            {(formikProps) => {
              function handleEventId(e) {
                axios({
                  url: `entry/entry/machine-list?entity_id=${e.target.value}`,
                  disableRedirect: true,
                  disableError: true,
                  customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
                }).then((response) => {
                  if (response.status) {
                    setGates(response.data);
                  }
                  formikProps.setFieldValue("eventID", e.target.value);
                });
              }

              function handleEntityType(e) {
                axios({
                  url: "/entry/entry/entity-list?entity_type=" + e.target.value,
                  disableRedirect: true,
                  disableError: true,
                  customAuthToken: "Bearer " + authToken,
                }).then((response) => {
                  if (response.status) {
                    setEventList(response.data);
                  } else {
                    setEventList([]);
                  }
                  formikProps.setFieldValue("entityType", e.target.value);
                });
              }
              return (
                <Fragment>
                  {/* {agentName && userStep !== "" && (
                    <Grid
                      container
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Grid item container xs={4} sx={{ alignItems: "center" }}>
                        <Button
                          style={{
                            color: "white",
                            borderRadius: "5px",
                            padding: " 7.5px 30px",
                            background: " rgb(179, 115, 0)",
                            border: "2px solid rgb(255 255 255)",
                          }}
                          onClick={() => {
                            const stopCamera = () => {
                              if (
                                videoRef.current &&
                                videoRef.current.srcObject
                              ) {
                                const tracks =
                                  videoRef.current.srcObject.getTracks();
                                tracks.forEach((track) => track.stop());
                                videoRef.current.srcObject = null;
                              }
                            };

                            stopCamera();

                            navigate("/main-gate-entry-front");
                          }}
                        >
                          <BsCameraFill />
                        </Button>
                      </Grid>

                      <Grid
                        item
                        container
                        xs={4}
                        sx={{ alignItems: "center", justifyContent: "end" }}
                      >
                        <Typography alignContent={"center"}>
                          Welcome
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {agentName}
                          </span>{" "}
                        </Typography>

                        <Tooltip title="Edit Name">
                          <EditRoundedIcon
                            sx={{
                              fontSize: "30px",
                              borderRadius: "15px",
                              padding: "5px",
                              "&:hover": {
                                background: "rgb(108 108 108 / 87%)",
                              },
                            }}
                            onClick={() => {
                              const stopCamera = () => {
                                if (
                                  videoRef.current &&
                                  videoRef.current?.srcObject
                                ) {
                                  const tracks =
                                    videoRef.current.srcObject?.getTracks();
                                  tracks.forEach((track) => track.stop());
                                  videoRef.current.srcObject = null;
                                }
                              };

                              stopCamera();

                              setUserStep("");
                            }}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )} */}

                  {userStep === "" && (
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          select
                          name="entityType"
                          label="Select Entity Type"
                          formikProps={formikProps}
                          onChange={handleEntityType}
                        >
                          <MenuItem value={"event"}>Event</MenuItem>
                          <MenuItem value={"campus"}>Campus</MenuItem>
                        </TextField>
                      </Grid>

                      <Grid item xs={12} marginTop={"10px"}>
                        <TextField
                          select
                          name="eventID"
                          label="Select Event Name"
                          formikProps={formikProps}
                          onChange={handleEventId}
                          disabled={!formikProps.values.entityType}
                        >
                          {eventList?.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item?.entity_id}>
                                {item?.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>

                      <Grid item xs={12} marginTop={"10px"}>
                        <LoadingButton
                          variant="contained"
                          sx={{ height: "100%" }}
                          disabled={!formikProps.dirty || !eventList.length}
                          onClick={() => {
                            setAgentName(formikProps.values.name);
                            dispatch(setDisplayName(formikProps.values.name));
                            setUserStep("QRcode");
                          }}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  )}

                  {userStep === "QRcode" && (
                    <Grid container>
                      <Grid item container m={1}>
                        <Grid item xs={4}>
                          <Button
                            variant="contained"
                            onClick={() => {
                              setUserStep("");
                            }}
                          >
                            <ArrowBackIcon />
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            select
                            name="gateNo"
                            size="small"
                            formikProps={formikProps}
                            onChange={(e) => {
                              sessionStorage.setItem(
                                "gateMateNo",
                                e.target.value
                              );

                              setSelectedGate(e.target.value);

                              formikProps.setValues((prev) => {
                                return { ...prev, gateNo: e.target.value };
                              });
                            }}
                            value={selectedGate}
                          >
                            <MenuItem value={0}>
                              Select Your Current Gate
                            </MenuItem>
                            {gates.map((item) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.zone_name}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Grid>
                      </Grid>

                      <ImgCapture
                        loading={loading}
                        videoRef={videoRef}
                        bioFormikProps={formikProps}
                        cameraScreen={cameraScreen}
                        onSubmit={formikProps.handleSubmit}
                      />

                      {/* {Boolean(selectedGate !== 0) && (
                        <ImgCapture
                          loading={loading}
                          videoRef={videoRef}
                          bioFormikProps={formikProps}
                          cameraScreen={cameraScreen}
                          onSubmit={formikProps.handleSubmit}
                        />
                      )} */}
                    </Grid>
                  )}
                </Fragment>
              );
            }}
          </Formik>
        )}

      {entryStatus && (
        <Grid
          container
          display={"flex"}
          sx={{ flexDirection: "column" }}
          alignItems="center"
          justifyContent={"center"}
        >
          <audio
            autoPlay
            src="https://broadcast-contact-numbers.s3.ap-south-1.amazonaws.com/output_1719227404.694377_aws.wav"
            // loop
          >
            {" "}
          </audio>

          <CheckCircleOutlineRoundedIcon
            sx={{
              fontSize: "70px",
              lineHeight: "21px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "rgb(41 128 31)",
              marginBottom: "30px",
            }}
          />
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontWeight: 700,
              fontSize: "25px",
              lineHeight: "21px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "rgb(41 128 31)",
            }}
          >
            Access Granted
          </Typography>
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "21px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "rgb(108 108 108)",
              mt: "15px",
            }}
          >
            {`Welcome ${userDetail?.first_name ? userDetail?.first_name : ""}${
              userDetail?.last_name ? userDetail?.last_name : ""
            }!`}
          </Typography>
          <Button
            variant="outlined"
            sx={{ marginTop: "30px" }}
            onClick={() => {
              setEntryStatus(false);
              setFaceVerificationStatus(undefined);
              setAppointmentStatus(undefined);
            }}
          >
            Take Next Entry
          </Button>
        </Grid>
      )}

      {/* {bookingExistsStatus !== undefined && bookingExistsStatus === false && (
        <StatusMessage
          icon={CancelRoundedIcon}
          iconColor="red"
          text="Booking not found"
          buttonText="Try Again"
          onClick={resetStatuses}
        />
      )} */}

      {appointmentStatus !== undefined && appointmentStatus === false && (
        <StatusMessage
          icon={CancelRoundedIcon}
          iconColor="red"
          text="Visitor is not verified"
          buttonText="Try Again"
          onClick={resetStatuses}
        />
      )}

      {faceVerificationStatus !== undefined &&
        faceVerificationStatus === false &&
        appointmentStatus !== false && (
          <StatusMessage
            icon={CancelRoundedIcon}
            iconColor="red"
            text="Face Didn't Match"
            buttonText="Try Again"
            onClick={resetStatuses}
          />
        )}

      {appointmentStatus === true && faceVerificationStatus !== false && (
        <StatusMessage
          icon={CancelRoundedIcon}
          iconColor="red"
          text="Please verify at the main gate first"
          buttonText="Try Again"
          onClick={resetStatuses}
        />
      )}
    </OuterBox>
  );
};

export default MainGateEntryWithBackCamera;
