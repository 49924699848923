import { Grid, Typography } from "@mui/material";
import { Fragment } from "react";

export default function EventBadge({
  index,
  eventID,
  formikProps,
  eventObject,
  badgeOptions,
}) {
  const handleEventBadgeSelect = (ID, index) => {
    const selectedEventId = ID;

    const selectedEvent = badgeOptions.find(
      (event) => event?._id === selectedEventId
    );

    formikProps.setFieldValue(
      `visitorData[${index}].badge_id`,
      formikProps.values.visitorData[index]?.badge_id ? "" : selectedEvent?._id
    );

    formikProps.setFieldValue(
      `visitorData[${index}].badge_id_amount`,
      formikProps.values.visitorData[index]?.badge_id_amount
        ? 0
        : parseInt(selectedEvent?.price)
    );

    if (eventID && !formikProps.values.eventAmount) {
      formikProps.setFieldValue(
        "eventAmount",
        parseInt(eventObject.ticket_cost)
      );
    }
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          mb={3}
          fontWeight={700}
          color={"rgb(0 0 0 / 61%)"}
        >
          Select Event Badge
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        display={"flex"}
        width={0}
        sx={{
          border: " 1px solid rgb(232, 222, 207)",
          borderRadius: "12.5px",
          overflowY: "scroll",
          scrollbarWidth: "none",
          flexDirection: "row",
          padding: "10px 5px !important",
        }}
      >
        {badgeOptions.map((items, indx) => {
          return (
            <div
              style={{
                margin: "5px",
                background: items.color,
                padding: "10px 20px",
                borderRadius: "12.5px",
                boxShadow:
                  formikProps.values?.visitorData[index]?.badge_id ===
                    items._id && "rgb(49, 49, 48) 0px 0px 1px 2px",

                pointerEvents:
                  formikProps.values.paymentStatue === "Pending" && "none",
              }}
              onClick={() => handleEventBadgeSelect(items?._id, index)}
            >
              <Typography sx={{ fontWeight: 900, textAlign: "start" }}>
                {items.badge_name}
              </Typography>

              <Typography
                sx={{
                  fontWeight: 900,
                  textAlign: "start",
                  color: "rgb(233 175 71)",
                }}
              >
                Rs {items.price}
              </Typography>
            </div>
          );
        })}
      </Grid>
    </Fragment>
  );
}
