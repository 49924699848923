import home from "../../pages/home components/Home";
import appointment from "../../pages/appointment components/Home";
import previewVideo from "../../pages/Liveliness/PreviewVideo";
import Liveliness from "../../pages/Liveliness/CaptureVideo";
import Success from "../../pages/Success/";
import Verification from "../../pages/Verification/Verificaion";
import UploadDocument from "../../pages/UploadDocument/UploadDocument";
import EventBooking from "../../pages/Booking/EventBooking/EventBooking";
import AppointmentBooking from "../../pages/Booking/AppointmentBooking/AppointmentBooking";
import EventBookingDetails from "../../pages/Booking/EventBooking/EventBookingDetails";
import AppointmentBookingDetails from "../../pages/Booking/AppointmentBooking/AppointmentBookingDetails";
import AadharverificaionDetails from "../../pages/Verification/Aadharverificaion/AadharverificaionDetails";
import DrivingLicenceDetails from "../../pages/Verification/DrivingLicence/DrivingLicenceDetails";
import BioVerificaion from "./../../pages/Booking/BioVerification/BioVerificaion";
import TrackAppointment from "./../../pages/Track Appointment/index.js";
import ContactUs from "../../pages/home components/Contact.js";
import AadharverificaionSuccess from "../../pages/Verification/Aadharverificaion/AadharverificaionSuccess.js";
import ManualEntry from "../../pages/Manual Entry Components/ManualEntry.js";
import ResendNotification from "../../pages/Resend Notification Components/ResendNotification.js";
import Form from "../../pages/sign in components/Form.js";
import ManualVerification from "../../pages/Manual Verification Components/ManualVerification.js";
import MainGateEntryWithBackCamera from "../../pages/MainGate Entry With Back Camera  Components/MainGateEntry.js";
import MainGateEntryWithFrontCamera from "../../pages/MainGate Entry With Front Camera  Components/MainGateEntry.js";
import Consent from "../../pages/Consent components/Consent.js";
import EventList from "../../pages/Event Components/EventList.js";
import EventSummary from "../../pages/Event Summary Components/EventSummary.js";
import BoardingPass from "../../pages/Boarding Pass components/BoardingPass.js";
import HomeComponent from "../../pages/home components/Home";
import AppointmentSummary from "../../pages/Appointment Summary Components/AppointmentSummary.js";
import ShopsMore from "../../pages/Shops More components/ShopsMore.js";
import ShopListView from "../../pages/Shops More components/Shops/ListView.js";
import HotelListView from "../../pages/Shops More components/Hotels/HotelListView.js";
import ShopView from "../../pages/Shops More components/Shops/ShopView.js";
import HotelView from "../../pages/Shops More components/Hotels/HotelView.js";
import Profile from "../../pages/Profile components/Profile.js";
import Transaction from "./../../pages/Transaction/Transaction";
import CampusSummary from "../../pages/Campus Summary Components/CampusSummary.js";
import HotelBooking from "../../pages/Hotel components/Hotel.js";
import AddHotelBooking from "../../pages/Hotel components/AddHotelBooking.js";
import EventDetail from "../../pages/Event Components/Event Details/EventDetail.js";

const applicationLayoutRoutes = {
  homePageRoute: {
    ID: "Home",
    path: "/",
    element: HomeComponent,
  },

  eventListPageRoute: {
    ID: "EventList",
    path: "/event-list",
    element: EventList,
  },

  appointmentPageRoute: {
    ID: "appointment",
    path: "/event/" + ":eventID",
    element: appointment,
  },

  ContactUs: {
    ID: "ContactUs",
    path: "/contact-us",
    element: ContactUs,
  },

  // AboutUs: {
  //   ID: "AboutUs",
  //   path: "/about-us",
  //   element: AboutUs,
  // },

  signInRoute: {
    ID: "sign-in",
    path: "/sign-in",
    element: Form,
  },

  TrackAppointmentPageRoute: {
    ID: "TrackAppointment",
    path: "/track-appointment",
    element: TrackAppointment,
  },

  previewVideoPageRoute: {
    ID: "previewVideo",
    path: "/event/previewVideo",
    element: previewVideo,
  },

  livelinessPageRoute: {
    ID: "liveliness",
    path: "/event/booking-verification",
    element: Liveliness,
  },

  Success: {
    ID: "Success",
    path: "/success",
    element: Success,
  },

  AadharverificaionSuccess: {
    ID: "AadharverificaionSuccess",
    path: "/aadhar-verificaion-success",
    element: AadharverificaionSuccess,
  },

  Verification: {
    ID: "Verification",
    path: "/event/verification",
    element: Verification,
  },

  CampusVerification: {
    ID: "CampusVerification",
    path: "/campus/verification",
    element: Verification,
  },

  UploadDocument: {
    ID: "UploadDocument",
    path: "/upload-document",
    element: UploadDocument,
  },
  EventBooking: {
    ID: "EventBooking",
    path: "/event/event-booking/" + ":eventID" + "/" + ":id",
    element: EventBooking,
  },

  EventBookingDetails: {
    ID: "EventBookingDetails",
    path: "/event/event-booking-details",
    element: EventBookingDetails,
  },

  AppointmentBooking: {
    ID: "AppointmentBooking",
    path: "/appointment/appointment-booking",
    element: AppointmentBooking,
  },

  AppointmentBookingDetails: {
    ID: "AppointmentBookingDetails",
    path: "/appointment-booking-details",
    element: AppointmentBookingDetails,
  },

  AadharverificaionDetails: {
    ID: "AadharverificaionDetails",
    path: "/aadhar-verificaion-details",
    element: AadharverificaionDetails,
  },

  DrivingLicenceDetails: {
    ID: "DrivingLicenceDetails",
    path: "/driving-licence-details",
    element: DrivingLicenceDetails,
  },
  BioVerificaion: {
    ID: "BioVerificaion",
    path: "/bio-verification",
    element: BioVerificaion,
  },

  MainGateEntryWithBackCamera: {
    ID: "MainGateEntryWithBackCamera",
    path: "/main-gate-entry-back",
    element: MainGateEntryWithBackCamera,
  },

  MainGateEntryWithFrontCamera: {
    ID: "MainGateEntryWithFrontCamera",
    path: "/main-gate-entry-front",
    element: MainGateEntryWithFrontCamera,
  },

  ManualEntry: {
    ID: "ManualEntry",
    path: "/manual-entry",
    element: ManualEntry,
  },

  ManualVerification: {
    ID: "ManualVerification",
    path: "/manual-verification",
    element: ManualVerification,
  },

  ResendNotification: {
    ID: "ResendNotification",
    path: "/resend-notification",
    element: ResendNotification,
  },

  Consent: {
    ID: "Consent",
    path: "/event/consent",
    element: Consent,
  },

  EventSummary: {
    ID: "EventSummary",
    path: "/event/event-summary/" + ":eventID" + "/" + ":id",
    element: EventSummary,
  },

  AppointmentSummary: {
    ID: "AppointmentSummary",
    path: "/appointment/Appointment-summary",
    element: AppointmentSummary,
  },

  BoardingPass: {
    ID: "BoardingPass",
    path: "/boarding-pass",
    element: BoardingPass,
  },

  ShopsMore: {
    ID: "ShopsMore",
    path: "/shops-more",
    element: ShopsMore,
  },

  Shops: {
    ID: "Shops",
    path: "/shops",
    element: ShopListView,
  },

  Hotels: {
    ID: "Hotels",
    path: "/hotels",
    element: HotelListView,
  },

  Shop: {
    ID: "Shops",
    path: "/shop",
    element: ShopView,
  },

  Hotel: {
    ID: "Hotel",
    path: "/hotel",
    element: HotelView,
  },

  Transaction: {
    ID: "Transaction",
    path: "/transaction",
    element: Transaction,
  },

  Profile: {
    ID: "Profile",
    path: "/profile",
    element: Profile,
  },

  CampusSummary: {
    ID: "CampusSummary",
    path: "/campus/campus-summary",
    element: CampusSummary,
  },

  eventListPageRoute: {
    ID: "EventList",
    path: "/event-list",
    element: EventList,
  },

  eventPageRoute: {
    ID: "Event",
    path: "/event",
    element: EventDetail,
  },

  HotelBookingPageRoute: {
    ID: "HotelBookingPageRoute",
    path: "/hotel-booking",
    element: HotelBooking,
  },

  AddHotelBookingPageRoute: {
    ID: "AddHotelBookingPageRoute",
    path: "/add-hotel-booking",
    element: AddHotelBooking,
  },
};

export default applicationLayoutRoutes;
